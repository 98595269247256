<template>
  <v-container
    id="widgets"
    fluid
    tag="section"
    class="pt-0"
  >
    <v-progress-linear
      v-if="!chartLoad"
      color="deep-purple accent-4"
      indeterminate
      rounded
      height="6"
    />
    <v-card v-else>
      <v-card-text style="overflow: auto;">
        <year-chart
          :options="gantOpt"
          :vac-date="valForChart"
          :chart-load="chartLoad"
        />
      </v-card-text>
    </v-card>
    <!-- ОКНО УДАЛЕНИЯ/ПОДТВЕРЖДЕНИЯ/ОТКЛАНЕНИЯ (END) -->
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import yearChart from '../components/yearChart'

  export default {
    name: 'VacantionsChart',
    components: { yearChart },
    data () {
      return {
        chartLoad: false,
        valForChart: null,
        gantOpt: {
        },
      }
    },
    computed: {
      ...mapGetters({
        empid: 'empid',
        VacEmployees: 'VacEmployees',
        VacDate: 'VacDate',
        monthNames: 'monthNames',
        isAuthenticated: 'isAuthenticated',
      }),
    },
    created () {
      this.$store.commit('setVacDate', [])
      var d = new Date()
      const dateFrom = (d.getFullYear()) + '-1-1'
      const dateTo = (d.getFullYear()) + '-12-31'
      var toSend = { dateTo: dateTo, dateFrom: dateFrom }
      Promise.all([this.$store.dispatch('getFullEmpl', toSend), this.$store.dispatch('getVacList', toSend)]).then((resp) => {
        // getVacList
        const forChart = []
        this.VacEmployees.forEach((x, index) => {
          forChart[index] = ({ EmpID: x.empid, ShortFIO: x.shortfio, items: [] })
          resp[1].data.forEach((item) => {
            if (x.empid == item.empid) {
              const normDate = new Date(item.datefroms.split('.')[2] + '-' + item.datefroms.split('.')[1] + '-' + item.datefroms.split('.')[0])
              const width = (item.datetill - item.datefrom)
              forChart[index].items.push({ startX: normDate, width: width, item: item })
            }
          })
        })
        this.valForChart = forChart
        this.chartLoad = true
        // getVacList
      }).catch((error) => {
        this.tableLoading = false
        this.$store.dispatch('setSnackbar', { state: true, text: error, color: 'warning', direction: 'top center' })
      })
    },
    methods: {
    },
  }
</script>
<style>
  .spaceout{
   border:0;
  }
  .anima{
    -webkit-transition: width 2s, height 4s; /* For Safari 3.1 to 6.0 */
    transition: width 2s, height 4s;
  }

  .v-data-table__selected .v-data-table__mobile-row{
    background-color: #52f599;
  }
  .emps .v-data-table__selected{
    background-color: #52f599;
  }
</style>
