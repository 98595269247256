<template>
  <div :style="'min-width: ' + width +'px'">
    <svg
      v-if="chartLoad"
      ref="chart"
      xmlns:ct="http://gionkunz.github.com/chartist-js/ct"
      height="100%"
      width="100%"
      :viewBox="'0 0 '+ width + ' ' + height"
      class="ct-chart-line"
    >
      <g class="ct-labels">
        <rect
          :x="todayX"
          :y="15"
          :width="oneDayWidth"
          :height="height"
          style="fill:rgba(66,233,80,0.2);"
        />
        <foreignObject
          v-for="(i) in monthInYear"
          :key="i"
          style="overflow: visible;"
          :x="lineX(i)"
          :y="height"
          :width="lineWidth"
          height="20"
          :transform="'rotate(-90 '+(lineWidth*i + 130) + ' ' + (height + 20)+')'"
        >
          <span
            class="ct-label ct-horizontal ct-end months"
            xmlns="http://www.w3.org/2000/xmlns/"
            style="width: 57px; height: 20px;"
          >{{ locale.months[i-1] }}</span>
        </foreignObject>
      </g>
      <g class="ct-grids">
        <line
          :x1="lineX(0)"
          :x2="lineX(0)"
          y1="15"
          :y2="height"
          class="ct-grid ct-horizontal"
          opacity="0.3"
        />
        <line
          v-for="i in monthInYear"
          :key="i"
          :x1="lineX(i)"
          :x2="lineX(i)"
          y1="15"
          :y2="height"
          class="ct-grid ct-horizontal"
          opacity="0.3"
        />
      </g>
      <g
        v-for="(item,index) in VacDate"
        :key="index"
      ><g class="ct-series ct-series-a">
        <foreignObject
          style="overflow: visible;"
          :y="labelsLeftY(index)"
          x="10"
          :height="itemsHeight"
          width="120"
        ><span
          class="ct-label ct-vertical ct-start mt-1"
          xmlns="http://www.w3.org/2000/xmlns/"
          :style="'height: 20px; width: 120px;'"
        >{{ item.ShortFIO }}</span>
        </foreignObject>
        <g v-if="item.items.length>0">
          <rect
            v-for="(i,ri) in item.items"
            :x="vacItem(i).startX"
            :y="labelsLeftY(index)"
            :width="vacItem(i).width"
            :height="itemsHeight/2"
            :key="'rect'+ri"
            rx="2"
            :style="'fill:'+getColor(i.item.state)"
            @mouseover="showToolTip(vacItem(i).startX, labelsLeftY(index), vacItem(i).width, i)"
            @mouseleave="hideToolTip()"
          />
        </g>
      </g>
      </g>
      <g class="toolTip">
        <rect
          v-show="backLine.show"
          :x="backLine.x"
          :y="15"
          :width="backLine.width"
          :height="backLine.height"
          style="fill:rgb(199,181,181,0.1);"
        />
        <rect
          v-show="backLine.show"
          :x="backLine.x"
          :y="backLine.y"
          :width="backLine.width"
          :height="height-backLine.y"
          style="fill:rgba(199,181,181,0.1);"
        />
        <foreignObject
          v-show="toolTip.show"
          style="overflow: visible;"
          :x="toolTip.x"
          :y="toolTip.y"
          width="20"
          height="20"
        ><div
          class="div-tooltip font-weight-thin"
          xmlns="http://www.w3.org/2000/xmlns/"
        >{{ toolTip.text }}</div>
        </foreignObject>
      </g>
    </svg>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'YearChart',
    props: {
      AxisX: {
        type: Array,
      },
      AxisY: {
        type: Array,
      },
      VacDate: {
        type: Array,
      },
      disablebtn: {
        type: Boolean,
        default: false,
      },
      chartLoad: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        min: 0,
        max: 5,
        pallete: [
          '#E74C3C',
          '#DA3C78',
          '#7E349D',
          '#0077C0',
          '#07ABA0',
          '#0EAC51',
          '#F1892D',
          '#E3724B',
          '#AE7C5B',
          '#6C7A89',
          '#758586',
          '#707070',
        ],
        toolTip: { x: 10, y: 10, text: '', show: false },
        backLine: { x: 10, y: 10, width: 10, height: 10, show: false },
        zoomY: 1,
        monthInYear: 12,
        defIndent: 50,
        lineWidth: 40,
        itemsHeight: 65,
        locale: {
          name: 'ru',
          months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
          monthsShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
          weekdays: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
          weekdaysShort: ['вос', 'пон', 'вто', 'сре', 'чет', 'пят', 'суб'],
          weekdaysMin: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
        },
        labelsIndentX: 120,
        labelsIndentY: 45,
        days: null,
        oneDayWidth: 20,
        height: 600,
        width: 1100,
        thisYear: new Date().getFullYear(),
        firstDayOfYear: new Date(),
        firstDayOfWeek: 0,
        yearDays: 365,
        daysNameShort: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
      }
    },
    mounted () {
      const isLeapYear = y => !(y & 3 || y & 15 && !(y % 25))
      const year = this.firstDayOfYear.getFullYear()
      if (isLeapYear(year)) {
        this.yearDays++
      }
      this.days = (new Date(year + '-02-01') - new Date(year + '-12-01')) / (24 * 60 * 60 * 1000)
      this.firstDayOfYear = new Date(year + '-1-1')
      this.firstDayOfWeek = this.firstDayOfYear.getDay() - 1
      this.oneDayWidth = (this.width - this.labelsIndentX - this.defIndent) / this.yearDays
      this.lineWidth = (this.width - this.labelsIndentX - this.defIndent) / this.monthInYear
      this.width = this.width * this.zoomY
    },
    computed: {
      ...mapGetters({
        VacEmployees: 'VacEmployees',
        empid: 'empid',
        isAuthenticated: 'isAuthenticated',
      }),
      selectedEmp: {
        get: function () {
          return this.$store.getters.selectedEmp
        },
        set: function (newValue) {
          this.$store.dispatch('setSelectedEmp', newValue)
        },
      },
      todayX () {
        const x = new Date()
        const y = (x - this.firstDayOfYear) / (24 * 60 * 60 * 1000) * this.oneDayWidth + this.labelsIndentX + this.defIndent
        return y
      },
    },
    watch: {
      chartLoad (val) {
        if (this.VacDate.length > 10) {
          this.itemsHeight = 40
        }
        this.height = this.VacDate.length * this.itemsHeight + this.labelsIndentY
      },
    },
    methods: {
      getColor (i) {
        if (i >= 0) {
          switch (i) {
            case 0:
              return '#ccecf8'
              break
            case 1:
              return '#9af4c8'
              break
            case 2:
              return '#35f42e'
            default:
              return '#c7b5b5'
              break
          }
        } else return '#c75e35' // this.pallete[i]
      },
      hideToolTip () {
        this.toolTip.show = false
        this.backLine.show = false
      },
      showToolTip (x, y, w, i) {
        const text = ' c ' + i.item.datefroms + '\n по ' + i.item.datetills
        const h = y - 18
        y = y + this.itemsHeight / 2 + 2
        let x1 = x
        const x2 = x
        if (this.width - x < 120) { x1 = x + w - 122 }
        this.toolTip = { x: x1, y: y, text: text, show: true }
        this.backLine = { x: x2, y: y, height: h, width: w, show: true }
      },
      lineX (i) {
        if (i == 0) {
          return this.labelsIndentX + this.defIndent
        } else {
          return this.daysFromStartYear(i) * this.oneDayWidth + this.labelsIndentX + this.defIndent
        }
      },
      daysFromStartYear (month, day) {
        let daysFromStartYear = 0
        if (day) {
          daysFromStartYear = ((new Date(this.thisYear, month, day) - new Date(this.thisYear, 1, 1)) / (24 * 60 * 60 * 1000) - 1)
        } else {
          daysFromStartYear = ((new Date(this.thisYear, month, 1) - new Date(this.thisYear, 0, 1)) / (24 * 60 * 60 * 1000) - 1)
        }
        return daysFromStartYear
      },
      labelsLeftY (index) {
        if (index == 0) {
          return 20
        } else {
          return index * ((this.height - this.labelsIndentY) / this.VacDate.length) + 10
        }
      },
      vacItem (item) {
        if (item) {
          const startX = (item.startX - this.firstDayOfYear) / (24 * 60 * 60 * 1000) * this.oneDayWidth + this.labelsIndentX + this.defIndent - this.oneDayWidth
          const width = (item.width + 1) * this.oneDayWidth
          return { startX: startX, width: width }
        } else { return { startX: 0, startY: 0, width: 0, height: 0 } }
      },
      weekends (day) {
        day = day + this.firstDayOfWeek
        return day % 7 == 6 || day % 7 == 0
      },
      dayString (day) {
        day = day + this.firstDayOfWeek
        return this.daysNameShort[day % 7]
      },
      dateOfMonth (item) {
        let tDate = new Date(this.firstDayOfYear.getTime() + (item) * 86400000)
        tDate = tDate.toISOString().substr(0, 10).split('-')
        if (tDate[2][0] == '0') {
          tDate[2] = tDate[2][1]
        }
        return tDate[2]
      },
    },
  }
</script>
<style>
  .div-tooltip{
    background-color: rgba(255, 255, 255);
    border: 1px solid #c7b5b5;
    border-radius: 6px;
    min-width: 120px;
    padding: 6px;
  }
  .ct-label.months {
    font-size: small;
    opacity: .3;
  }
  .upperLegend{
    stroke:rgb(199,181,181,0.5);
  }
  .rows{
    fill:none;
    stroke-width:1;
    stroke:rgb(199,181,181);
  }
  .rows:hover{
    fill: #b69dff;
  }
  .colums{
    fill:none;
    stroke-width:1;
    stroke:rgb(199,181,181);
  }
  .colums:hover{
    fill:red;
  }
</style>
